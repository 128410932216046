<template>
  <div id="sidebar_nav" v-bind:class="mob_MenuOpen ? 'mob_menu_show' : ''">
    <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
      <div class="menu_icon_bar">
        <div class="mob_menu_header">
          <a href="javascript:void(0)" class="mob_menu_back" @click="closeMenuAll">Back</a>
        </div>
        <div class="menu_scroller">
          <PerfectScrollbar>
            <ul>
              <template v-for="(mitem, index) in menuItems1" :key="index">
                <li v-if="canAccessMenu(mitem.can, mitem.id)" class="menu-item level_1"
                    v-bind:class="(activeNav == mitem.id ? 'active' : ''), (mitem.visibility ? 'visible_li' : 'disablelist')" v-bind:title="mitem.visibility ? '' : 'Coming Soon'">
                  <a href="javascript:void(0)" v-on:click="mobLink(mitem.href, mitem.id, mitem.visibility)" :data-id="mitem.id" :key="index">
                    <i class="menu-icon" v-bind:class="mitem.icon"></i>
                    <span class="full_label">{{ mitem.full_name }}</span>
                    <span class="short_label" v-bind:class="mitem.class">{{ mitem.label }}</span>
                  </a>
                </li>
              </template>
            </ul>
          </PerfectScrollbar>
        </div>
      </div>

      <div id="product_menus" class="product_menus" v-bind:style="menuOpen ? '' : ''" v-bind:class="classCtrl" v-show="menuOpen" :key="menuOpen">
        <template v-for="(menus, menuIndex) in  menuItems2 " :key="menuIndex">
          <div :id="menuIndex + '_menu'" class="menu_list" v-bind:class="activeNav == menuIndex ? 'showNav' : ''">
            <div class='menu_list_items menu_scroller'>
              <PerfectScrollbar>
                <ListLoader v-if="menuLoader"></ListLoader>
                <div class="mob_menu_header" v-if="!menuLoader">
                  <a href="javascript:void(0)" class="mob_menu_back" @click="secondMenuClose">Back</a>
                  <h3><i class="menu_header-icon" v-bind:class="'head_' + mob_label.icon"></i>{{ mob_label.full_name }}</h3>
                </div>

                <ul v-if="!menuLoader">
                  <template v-for="( module, index ) in  menus " :key="index">
                    <template v-if="checkMenuAccess(module,menuIndex)">
                      <li v-bind:class="isRouteActive(module.child, module.href)" v-if="canAccessMenu(module.can, menuIndex, module.access)" :id="'menu_item_'+module.id">
                        <a :href="routerOther(menuIndex,module.href)" class="normalroute" v-if="menuIndex != activeProd" :data-id="menuIndex">{{ module.label }}</a>
                        <router-link :to="routerOther(menuIndex,module.href)" class="vueroute" v-else>{{ module.label }}</router-link>
                        <ul v-if="module.child" v-bind:style="module.child.some(item => item.href === this.$route.path) ? 'display:block' : ''">
                          <template v-for="( childPage, childIndex ) in  module.child " :key="childIndex">
                            <template v-if="checkMenuAccess(childPage,menuIndex)">
                              <li v-bind:class="isRouteActive2(childPage.child, childPage.href)" v-if="canAccessMenu(childPage.can, menuIndex)">
                                <a :href="routerOther(menuIndex,childPage.href)" v-if="menuIndex != activeProd">{{ childPage.label }}</a>
                                <router-link :to="childPage.href" v-else>{{ childPage.label }}</router-link>
                              </li>
                            </template>
                          </template>
                        </ul>
                      </li>
                    </template>
                  </template>
                </ul>
              </PerfectScrollbar>
            </div>
          </div>
        </template>
      </div>
    </aside>
  </div>
</template>

<script>
import $ from 'jquery';
import menujson from '@/assets/menu.json';
import {decodeKeycloakToken, getRoles, canAccessMenu } from '@/keycloakAuth';
import ListLoader from './../../src/views/Loader/ListLoader.vue';
import axios from "axios";

export default {
  name: 'SidebarLayout',
  components:{
    ListLoader
  },
  created() {
    this.emitter.on('showmenu-event', (evt) => {
      this.menuOpen = evt;
    })
    this.emitter.on('showmenu-mob', (evt) => {
      this.mob_MenuOpen = evt;
    })
    this.emitter.on('userProfileEvent', (evt) => {
      if( evt.partnersLength < 1) $('#menu_item_parner_nav').addClass('d-none');
    });
  },
  watch: {
    $route(to, from) {
      if (to.path !== '#' && to.path !== from.path) {
        if (this.mob_MenuOpen) {
          this.mob_MenuOpen = false;
          this.emitter.emit('mobTogglerClose', false)
          $('body').removeClass('oveflowhidden');
        }
      } else {
        /* Dropdown menu clicking */
      }
    }
  },
  data() {
    return {
      menuOpen: true,
      mob_MenuOpen: false,
      sec_menuClass: false,
      mob_label: {},
      activeNav: "mms",
      menuData: [],
      menuItems1: menujson['iconbar'],
      menuItems2: menujson['secondary'],
      menuLoader: false
    }
  },
  computed: {
    activeProd() {
      let siteOrigin = new URL(window.location.href).pathname.match(/[^\/]+/g);

      if(siteOrigin[1] === 'club') {
        this.activeNav = "mmsteam";
      }
      else{
        this.activeNav = siteOrigin[0];
      }

      return this.activeNav;
    },
    classCtrl() {
      if (this.mob_MenuOpen) {
        $('body').addClass('oveflowhidden');

        $('#product_menus').animate({ "left": "0px" }, "slow");

        return {
          'menu_items_show mob_items_visible': this.menuOpen,
        };
      } else {
        $('body').removeClass('oveflowhidden');

        return {
          'menu_items_show': this.menuOpen,
        }
      }
    }
  },
  methods: {
    canAccessMenu,
    mobLink(link,id,visibility){
      if (visibility && id != 'lms'  && id != 'mmsteam') {
        if(id != 'lms' && id != 'jobs' && id != 'shop'){
          this.activeMenu(id);
        }
      }
      let winwidth = $(window).width();
      if (winwidth >= 768) {
        if (link != '#') {
          this.menuLoader = (id != 'lms' && id != 'jobs' && id != 'shop');
          if (link == '/lms' || id == 'jobs' || id == 'shop') {
            if(link == '/lms'){
              window.open(
                  window.origin + '' + link,
                  '_blank'
              );
            }
            else{
              window.open(
                  link,
                  '_blank'
              );
            }
          } else {
            if (link.includes('http') || link.includes("https")) {
              if(id === 'lms'){
                window.open(link, '_blank');
              } else {
                window.location.href = link;
              }
            } else {
              window.location.href = window.origin + '' + link;
            }
          }
        }
      } else {
        if (link == '/lms') {
          window.open(
              window.origin + '' + link,
              '_blank'
          );
        } else {
          return false;
        }
      }
    },
    isRouteActive(child, route) {
      let cl = '';
      let currentRoute = this.$route.path;
      if (child) {
        const exists = child.some(childit => childit.href === currentRoute);
        if (exists) {
          cl += 'has-sub opened active';
        }
        else {
          cl += 'has-sub';
        }
      }
      if (this.$route.path === route) {
        cl += 'opened active';
      }
      return cl;
    },
    isRouteActive2(child, route) {
      let cl = '';
      let currentRoute = this.$route.path;
      if (child) {
        const exists = child.some(childit => childit.href === currentRoute);
        if (exists) {
          cl += 'has-sub opened active';
        }
        else {
          cl += 'has-sub';
        }
      }
      if (this.$route.path === route) {
        cl += 'active';
      }
      return cl;
    },
    activeMenu(id) {
      this.activeNav = id;
      this.mobActiveText()
      if (this.mob_MenuOpen) {
        this.sec_menuClass = true;
      } else {
        this.sec_menuClass = false;
      }
    },
    closeMenuAll() {
      this.mob_MenuOpen = false;
      this.sec_menuClass = false;
      this.secondMenuClose();
      this.emitter.emit('mobTogglerClose', false)
      $('body').removeClass('oveflowhidden');
    },
    secondMenuClose() {
      $('.menu_icon_bar').removeClass('showMenuList');
      $('#product_menus .menu_list').removeClass('showNav');
      $('.menu_list').removeClass('opened active');
    },
    mobActiveText() {
      let labeltext = this.activeNav
      let settingsIndex = this.menuItems1.findIndex(function (element) {
        return element.id === labeltext;
      });
      this.mob_label = this.menuItems1[settingsIndex];
    },
    routerOther(index,url) {
      let siteOrigin = new URL(window.location.href).pathname.match(/[^\/]+/g);
      if(index == 'mmsteam' && siteOrigin[1] !== 'club'){
        let path=window.origin+''+url;
        let pathArray = path.split('/').filter(Boolean);
     
        let winwidth = $(window).width();
        if (winwidth >= 768) {
          return url;
        }
        else{
          if(pathArray.includes('mms')){
            return url; 
          }else{
            return '/mms'+url;
          }          
        }      
      }
      else if(siteOrigin[1] == 'club' && index != 'mmsteam'){
        return "/mms"+url;
      }
      else{
        return url;
      }
    },
    maintoSubToggle() {
      this.menuOpen = true;
      this.emitter.emit('deskTogglerOpen', true);
    },
    checkPermission(data) {
      let api_roles = getRoles('ems');
      let user_roles = data;
      if (user_roles && api_roles) {
        let api_role_arr = api_roles;
        let user_role_arr = user_roles.split(',');
        let role_length = this.findCommonRoles(api_role_arr, user_role_arr);
        if (role_length) {
          return true
        }
      } else {
        return true;
      }
    },
    checkMenuAccess(can,module) {
      if(module=='mms'){
        let meta = can.access;
        let permissions = JSON.parse(JSON.stringify(this.$store.getters.fetchUserPermissions))
        return permissions.indexOf(meta) !== -1;
      }else{
        return true;
      }
    },
    filterMenu() {
      let meta = 'mmsteam';
      let permissions = JSON.parse(JSON.stringify(this.$store.getters.fetchUserPermissions))
      let access = permissions.indexOf(meta) !== -1;
      if(access === false){
        this.menuItems1= this.menuItems1.filter( item => item.id!='mmsteam' );
      }
      /* demo instance only used job product menu. */
      let client = this.client = window.location.hostname.split('.')[0];
      let userprofile = decodeKeycloakToken();
      if(client !== 'demo') {
        this.menuItems1= this.menuItems1.filter( item => item.id!='jobs' );
      }
      else {
        /*js encrpty*/
        const tokenKeyEncrypt = salt => {
          const textToChars = text => text.split('').map(c => c.charCodeAt(0));
          const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
          const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

          return text => text.split('')
              .map(textToChars)
              .map(applySaltToChar)
              .map(byteHex)
              .join('');
        }

        const tokenLink = tokenKeyEncrypt('isportz');

        if(tokenLink){
          this.menuItems1.forEach(item => {
            if (item.id === 'jobs') {
              item.href = item.href+'?token='+tokenLink(userprofile.email);
            }
          });
        }
      }
      if (this.client === process.env.VUE_APP_SHOOTING_REALM) {
        this.menuItems1[0]['href'] = process.env.VUE_APP_SHOOTING_MMS_URL;
        this.menuItems1[2]['href'] = process.env.VUE_APP_SHOOTING_EMS_URL;
        this.menuItems1[4]['href'] = process.env.VUE_APP_SHOOTING_LMS_URL;
      }
    }
  },
  mounted() {
    window.sidebar = this;
    this.mobActiveText()
    const self = this;
    setTimeout(function(){
      self.filterMenu();
    },1000);
  }
};

$(document).on('click', '.menu_icon_bar .menu-item.visible_li > a', function () {
  let menu_id = $(this).data('id');
  if (menu_id == 'lms' || menu_id == 'jobs' || menu_id == 'shop') {
    return false;
  }

  window.sidebar.maintoSubToggle();

  $('.menu_icon_bar').addClass('showMenuList');
  $('.menu_icon_bar .menu-item').removeClass('active');
  $(this).parent('li').addClass('active');

  $('#product_menus').hide();
  $('#product_menus .menu_list').removeClass('showNav');
  $('#product_menus #' + menu_id + '_menu').addClass('showNav');
  $('#product_menus').show();
  $('.has-sub').removeClass('opened active');
});

$(document).on('click', 'li.has-sub>a', function () {
  $(this).removeAttr('href');
  let element = $(this).parent('li');
  if (element.hasClass('opened')) {
    element.removeClass('opened active');
    element.find('li').removeClass('opened active');
    element.find('ul').slideUp();
  }
  else {
    element.addClass('opened active');
    element.children('ul').slideDown();
    element.siblings('li').children('ul').slideUp();
    element.siblings('li').removeClass('opened active');
    element.siblings('li').find('li').removeClass('opened active');
    element.siblings('li').find('ul').slideUp();
  }
});
</script>